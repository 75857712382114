<template>

  <section>

    <b-card title="Contract Table">

      <!-- search input -->
      <div>         
        <b-row>
          <b-col cols="6"> 
              <b-button v-b-modal.modal-edit variant="primary" @click="newContract">New Contract</b-button>
          </b-col>
          <b-col cols="6">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
              </b-form-group>            
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="fields" :rows="contracts" ref="table"
        :search-options="{ enabled: true, externalQuery: searchTerm }"
        :pagination-options="{ enabled: true, perPage:pageLength }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'id'" class="text-nowrap">
            <a v-b-modal.modal-edit  @click="editContract(props.row.id)">{{ props.row.id }}</a>
          </span>
          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            <a v-b-modal.modal-edit  @click="editContract(props.row.id)">{{ props.row.name }}</a>
          </span>
          <span v-else-if="props.column.field === 'address'" class="text-nowrap">
            <a :href="props.row.url" target="_blank">{{ props.row.address }}</a>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select v-model="pageLength" :options="['10','20','50','100']" class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                first-number last-number align="right" prev-class="prev-item" next-class="next-item"
                class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">            
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
        </div>
        </template>
      </vue-good-table>

    </b-card>

    <b-modal ref="editmodal"
      id="modal-edit"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Cancel"      
      :title="editedContract.title"
      @ok="saveContract" :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group>
          Name <b-form-input v-model="editedContract.name" placeholder="Contract Name"/>          
        </b-form-group>
        <b-form-group>
          Description           
          <b-form-textarea rows="3" v-model="editedContract.description" placeholder="Contract Name"/>                      
        </b-form-group>
        <b-form-group>
          ChainId <b-form-input v-model="editedContract.chainId" placeholder="Contract Name"/>                      
        </b-form-group>
        <b-form-group>
          Address <b-form-input v-model="editedContract.address" placeholder="Contract Name"/>                      
        </b-form-group>
        <b-form-group>
          Url <b-form-input v-model="editedContract.url" placeholder="Contract Name"/>                      
        </b-form-group>
        <b-form-group>
          ABI 
          <b-form-textarea rows="5" v-model="editedContract.description" placeholder="ABIs"/>                      
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- b-table
      :fields="fields_"
      :items="items_"
    >
      <template #cell(name)="data">        
        <b-link :href="`#${data.value.replace(/[^a-z]+/i,'-').toLowerCase()}`">
          {{ data.value }}
        </b-link>
      </template>
    </b-table -->

  </section>

</template>

<script>

import { BIcon, BLink, BCard, BPagination, BForm, BTabs, BTab, BTable, BCardText, BButton, BFormSelect, BCol, BRow, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BFormTextarea } from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'

import Ripple from 'vue-ripple-directive'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ButtonSpinner from '@core/components/button-spinner/ButtonSpinner.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
    data: function() {
        return { 
            editedContract: {},
            pageLength: 10,
            fields: [
                { key: 'id', field: 'id', label: 'Id', formatter: value => `${value}` },
                { key: 'name', field: 'name', label: 'Name' },
                { key: 'address' , field: 'address', label: 'Address' },
                { key: 'description' , field: 'description', label: 'Description'},                
            ],
            searchTerm: '',

            fields_: [
                { key: 'name', label: 'Full Name', formatter: value => `${value.first} ${value.last}`, },        
                'age',
                { key: 'sex', formatter: value => value.charAt(0).toUpperCase(), },
                { key: 'birthYear', label: 'Birth Year', formatter: (value, key, item) => new Date().getFullYear() - item.age, },
            ],
            items_: [
                { name: { first: 'John', last: 'Doe' }, sex: 'Male', age: 42 },
                { name: { first: 'Clemens', last: 'Doe' }, sex: 'Female', age: 36 },
                { name: { first: 'Rubin', last: 'Kincade' }, sex: 'male', age: 73 },
                { name: { first: 'Shirley', last: 'Partridge' }, sex: 'female', age: 62 },
                { name: { first: 'Olenka', last: 'Brawley' }, sex: 'female', age: 26 },
            ],
        }
    },
    computed: {
        ...mapGetters("JCCDContract",["contracts", "isContractReady", "contractMap"]),   
    },
    methods: {
        ...mapActions("JCCDContract",["loadContracts", "updateContract", "createContract"]),
        ...mapActions("logs",["logToast"]),
        async editContract(id) {
            this.editedContract = { ...this.contractMap[id], mode : 'edit', title: 'Edit Contract' }; 
        },
        async newContract() {
            this.editedContract = {
                mode : 'new' ,
                name : '',
                description: '',
                chainId: '',
                address: '',
                abi: '',
                url: '',
                title: 'New Contract'
            }            
        },
        async saveContract(bvModalEvt) {            
            bvModalEvt.preventDefault();            
            if (this.editedContract.name === "") {
                bvModalEvt.preventDefault();
                this.showToast("error","Name is Empty");
                return;
            }
            if (this.editedContract.address === "") {
                bvModalEvt.preventDefault();
                this.showToast("error","Address is Empty");
                return;
            }
            if (this.editedContract.mode == 'edit') {
                await this.updateContract(this.editedContract).catch((error)=>{
                    if( error.response ){
                        console.log(error.response.data); // => the response payload 
                        this.showToast("danger","Caanot create contract", error.response.data.toString());
                    } else {
                        console.log(error)                                               
                        this.showToast("danger","Cannot create contract", error.toString());
                    }
                }).then((response)=>{ this.$refs.editmodal.hide() })
            } else {
                await this.createContract(this.editedContract).catch((error)=>{
                    if( error.response ){
                        console.log(error.response.data); // => the response payload 
                        this.showToast("danger","Caanot create contract", error.response.data.toString());
                    } else {
                        console.log(error)                                               
                        this.showToast("danger","Cannot create contract", error.toString());
                    }
                }).then((response)=>{ this.$refs.editmodal.hide() })
            }
        },
        showToast: function(variant, title, message) {
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant },
            })
        },       
    },
    mounted: function() {
        this.loadContracts()
    },
    components: {
        BCardText,
        BCardActions,
        BCard, BPagination, BIcon,
        BLink, BTable, BCol, BRow, BTabs, BTab, BButton, BFormSelect, BFormGroup, BFormInput, BInputGroup, BFormTextarea, BForm,
        VueGoodTable,
        BInputGroupPrepend,
        ButtonSpinner,
        ToastificationContent,
    },
    watch: {
        isUserConnected : function() { this.updateConnectionStatus() },
        getActiveAccount: function() { this.updateConnectionStatus() },
        getChainId: function() { this.updateConnectionStatus() },
        getContract: function() { this.updateConnectionStatus() },
        address: function() { this.updateContractState() },
    },
    directives: {
        Ripple,
    },    
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>